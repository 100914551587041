import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Sms1 } from '../../../components/images/support/users/Sms1.generated';
import { Sms2 } from '../../../components/images/support/users/Sms2.generated';
import { Sms3 } from '../../../components/images/support/users/Sms3.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "message-a-user-️"
    }}>{`Message a User ✉️`}</h1>
    <p>{`Canyou allows you to send an SMS messages directly to a user from inside the platform.`}</p>
    <p>{`This guide shows how to send an SMS message.`}</p>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the users menu and click into the profile of the user you wish to send an SMS to.`}</li>
    </ol>

    <Sms1 mdxType="Sms1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Click the `}<strong parentName="li">{`Message`}</strong>{` button.`}</li>
    </ol>

    <Sms2 mdxType="Sms2" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Write your message to the user and click `}<strong parentName="li">{`Send SMS`}</strong>{`. Please note that the user cannot respond to this message. `}</li>
    </ol>
    <p>{`In this example we direct the user to `}<a parentName="p" {...{
        "href": "https://app.canyou.com.au"
      }}>{`https://app.canyou.com.au`}</a>{` so they can login and finish their workflows`}</p>

    <Sms3 mdxType="Sms3" />
    <h1 {...{
      "id": "great-work-you-have-now-sent-an-sms-message-"
    }}>{`Great work you have now sent an SMS message 🥳`}</h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      